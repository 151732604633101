import Api from '@/services/Index';

const getAll = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/prep/students/', data);
}

const get = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/prep/students/' + id);
}

const update = async (id, data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/prep/students/' + id, data);
}

const del = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/prep/students/' + id);
}

const bulkDelete = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/prep/students/', data);
}

const store = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/prep/students/', data);
}

const createClassesFromPlacement = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/prep/students/from/placement', data);
}

const createClassesFromExcel = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/prep/students/from/excel', data);
}

const importExcel = (id,data)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/prep/students/import/'+id, data);
}

const getAbsenceReport = (data)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/prep/students/attendance-report', data);
}
const passedAbsenceReport = (data)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/prep/attendances/instructor-report', data,{responseType: 'arraybuffer'});
}
const massAbsenceUpdate = (data)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/prep/students/bulk-attendance-change', data);
}
const prepAttendances = (data)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/prep/students/excel-export', data,{responseType: 'arraybuffer'});
}
const getStudentDetailAbsenceReport = (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/student/documents/prep-attendance-list', config);
}




export default {
    getAll,
    get,
    del,
    store,
    update,
    createClassesFromPlacement,
    createClassesFromExcel,
    bulkDelete,
    importExcel,
    getAbsenceReport,
    passedAbsenceReport,
    massAbsenceUpdate,
    prepAttendances,
    getStudentDetailAbsenceReport
}
